import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, InputAdornment, TextField, TableSortLabel
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import PaymentModal from '../session-components/PaymentModal';
import { Link } from 'react-router-dom';

const SearchBar = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '1rem',
    },
}));

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    color: '#aaa',
});

const PaymentManagement = ({ coaches, siteManagers, attendees, sessionId }) => {
    const [fetchedCoaches, setFetchedCoaches] = useState([]);
    const [fetchedSiteManagers, setFetchedSiteManagers] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    // Fetch payees (coaches and site managers) from the endpoint
    useEffect(() => {
        const fetchPayees = async () => {
            try {
                const response = await axios.get(`/api/session/${sessionId}/payees`);
                const { coaches, siteManagers } = response.data;
                setFetchedCoaches(coaches);
                setFetchedSiteManagers(siteManagers);
            } catch (error) {
                console.error('Error fetching payees:', error);
            }
        };

        if (sessionId) {
            fetchPayees();
        }
    }, [sessionId]);

    const handlePayClick = (person) => {
        setSelectedPerson(person);
        setModalOpen(true); // Opens the payment modal when a person is clicked
    };

    const handleClose = () => {
        setModalOpen(false);
        setSelectedPerson(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSort = (key) => {
        setSortConfig((prevState) => ({
            key,
            direction: prevState.key === key && prevState.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const getHoursWorked = (owed, rate) => {
        if (!rate || !owed) return 'N/A';
        return (owed / rate).toFixed(2);
    };

    const getAmountOwed = (owed) => {
        return owed ? `$${owed.toFixed(2)}` : 'N/A';
    };

    // Only show coaches and siteManagers whose firebaseUid exists in the attendees array
    const filteredCoachesAndManagers = [...fetchedCoaches, ...fetchedSiteManagers].filter((person) =>
        attendees.includes(person.firebaseUid)
    );

    const sortedData = (data) => {
        if (!sortConfig.key) return data;
        return [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredList = sortedData(filteredCoachesAndManagers.filter((person) => {
        return person.name.toLowerCase().includes(searchQuery.toLowerCase());
    }));

    const paginatedData = filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const getLinkForPerson = (person) => {
        if (fetchedCoaches.some(coach => coach.firebaseUid === person.firebaseUid)) {
            return `/coach/${person.firebaseUid}`;
        }
        if (fetchedSiteManagers.some(manager => manager.firebaseUid === person.firebaseUid)) {
            return `/site-manager/${person.firebaseUid}`;
        }
        return '#';
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ mb: 2, color: '#12c099', fontWeight: 'bold' }}>
                Payment Management
            </Typography>

            <SearchBar
                variant="outlined"
                label="Search by Name"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#fff' }} />
                        </InputAdornment>
                    ),
                }}
            />

            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#fff' }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'name'}
                                    direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('name')}
                                    sx={{ color: '#fff' }}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ color: '#fff' }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'rate'}
                                    direction={sortConfig.key === 'rate' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('rate')}
                                    sx={{ color: '#fff' }}
                                >
                                    Rate (per hour)
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ color: '#fff' }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'hoursWorked'}
                                    direction={sortConfig.key === 'hoursWorked' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('hoursWorked')}
                                    sx={{ color: '#fff' }}
                                >
                                    Hours Worked
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ color: '#fff' }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'owed'}
                                    direction={sortConfig.key === 'owed' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('owed')}
                                    sx={{ color: '#fff' }}
                                >
                                    Amount Owed
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ color: '#fff' }} align="center">
                                Pay
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((person, index) => (
                            <TableRow key={index} hover sx={{ '&:hover': { backgroundColor: '#2b2b2b' } }}>
                                <CompactTableCell>
                                    <Link
                                        to={getLinkForPerson(person)}
                                        style={{ color: '#12c099', textDecoration: 'none', fontWeight: 'bold' }}
                                    >
                                        {person.name}
                                    </Link>
                                </CompactTableCell>
                                <CompactTableCell>
                                    {person.rate ? `$${person.rate}/hour` : 'N/A'}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {getHoursWorked(person.owed, person.rate)} hours
                                </CompactTableCell>
                                <CompactTableCell>
                                    {getAmountOwed(person.owed)}
                                </CompactTableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#12c099',
                                            color: '#fff',
                                            fontSize: '0.75rem',
                                            padding: '6px 10px',
                                            '&:hover': {
                                                backgroundColor: '#0f9f85',
                                            },
                                        }}
                                        onClick={() => handlePayClick(person)} // This is where `handlePayClick` is used
                                    >
                                        Pay Now
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={filteredList.length}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    sx={{ backgroundColor: '#202020', color: '#fff' }}
                />
            </StyledTableContainer>

            {selectedPerson && (
                <PaymentModal
                    open={modalOpen}
                    handleClose={handleClose}
                    details={`Paying ${selectedPerson.name} a total of $${selectedPerson.owed}`}
                    selectedPerson={selectedPerson}
                />
            )}
        </Box>
    );
};

export default PaymentManagement;
