import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';

const QuotasComponent = ({ quotaType, address = {}, sport = 'Unknown Sport', employeeId }) => {
    const [quota, setQuota] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [employeeQuota, setEmployeeQuota] = useState(0);
    const [regionUserCount, setRegionUserCount] = useState(0);
    const [teamMembers, setTeamMembers] = useState(0); // Number of employees in the region
    const [regionQuota, setRegionQuota] = useState(0); // State for regionQuota
    const maxSessionsPerEmployee = 160; // Max sessions an employee can organize

    const { city = 'Unknown City', state = ['Unknown State'], country = 'Unknown Country' } = address; // Destructure with default values
    const currentCity = Array.isArray(city) ? city[0] : city;
    const currentState = Array.isArray(state) ? state[0] : state;

    useEffect(() => {
        if (!currentCity || !currentState || !country || !sport || currentCity === 'Unknown City' || currentState === 'Unknown State' || country === 'Unknown Country') {
            console.warn('Skipping API fetch due to missing or invalid location or sport data');
            return;
        }

        const fetchQuotaAndUserCount = async () => {
            try {
                // Fetch the total number of users globally (across all sports)
                const userResponse = await axios.get('/api/users/count');
                const totalUsers = userResponse.data.totalUsers || 0;
                setUserCount(totalUsers);

                // Fetch the number of users for the specific region and sport (assigned to the employee)
                const regionUserResponse = await axios.get(`/api/users/count?city=${currentCity}&state=${currentState}&country=${country}&sport=${sport}`);
                const regionUsers = regionUserResponse.data.regionUsers || 0;
                setRegionUserCount(regionUsers);

                // Calculate the number of groups (each group contains 35 users)
                const globalGroupsOf35 = totalUsers > 0 ? Math.ceil(totalUsers / 35) : 0;
                const regionGroupsOf35 = regionUsers > 0 ? Math.ceil(regionUsers / 35) : 0;

                // Fetch the number of team members for the specific region and sport
                const teamResponse = await axios.get(`/api/teams?city=${currentCity}&state=${currentState}&country=${country}&sport=${sport}`);
                const totalTeamMembers = teamResponse.data.teamMembers || 0;
                setTeamMembers(totalTeamMembers);

                // Calculate the quota based on quotaType (monthly, weekly, daily) and number of groups
                let globalQuota = 0;
                let regionQuotaCalc = 0;

                switch (quotaType) {
                    case 'monthly':
                        globalQuota = globalGroupsOf35 * 4; // 4 sessions per group per month globally
                        regionQuotaCalc = regionGroupsOf35 * 4; // 4 sessions per group per month for the region
                        break;
                    case 'weekly':
                        globalQuota = (globalGroupsOf35 * 4) / 4; // Weekly global quota
                        regionQuotaCalc = (regionGroupsOf35 * 4) / 4; // Weekly regional quota
                        break;
                    case 'daily':
                        globalQuota = (globalGroupsOf35 * 4) / 30; // Daily global quota
                        regionQuotaCalc = (regionGroupsOf35 * 4) / 30; // Daily regional quota
                        break;
                    default:
                        break;
                }

                setQuota(globalQuota);
                setRegionQuota(regionQuotaCalc); // Set region quota

                // Calculate employee-specific quota
                const totalRegionQuota = regionQuotaCalc && totalTeamMembers > 0
                    ? Math.min(regionQuotaCalc, totalTeamMembers * maxSessionsPerEmployee)
                    : 0;
                const individualQuota = totalTeamMembers > 0 ? totalRegionQuota / totalTeamMembers : 0;
                setEmployeeQuota(Math.min(individualQuota, maxSessionsPerEmployee));

            } catch (error) {
                console.error(`Error fetching ${quotaType} quota or user count:`, error);
            }
        };

        // Ensure the sport is available before fetching data
        if (sport) {
            fetchQuotaAndUserCount();  // Fetch the user count and calculate the quota on component mount
        }
    }, [quotaType, currentCity, currentState, country, sport]);

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '2rem', borderRadius: '12px', minHeight: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent>
                <Typography variant="h6">
                    {quotaType.charAt(0).toUpperCase() + quotaType.slice(1)} Session Quota
                </Typography>
                <Typography variant="h4" sx={{ color: '#12c099', fontWeight: 'bold' }}>
                    {employeeQuota.toFixed(2)} {/* Employee quota displayed */}
                </Typography>
                <Typography variant="body2">
                    Based on {regionUserCount} users in {currentCity}, {currentState} for {sport || 'Unknown Sport'}.
                </Typography>
                <Typography variant="body2">
                    Global Quota: {quota.toFixed(2)} | Region Quota: {isNaN(regionQuota) ? 0 : regionQuota.toFixed(2)}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default QuotasComponent;
