import React from 'react';
import { Paper, List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)({
    backgroundColor: '#282828',
    padding: '1rem',
    marginBottom: '2rem',
});

const SectionTitle = styled(Typography)({
    marginBottom: '1rem',
    color: '#12c099',
    fontWeight: 'bold',
    fontSize: '1.5rem',
});

const StyledListItemText = styled(ListItemText)({
    '& .MuiTypography-root': {
        color: '#fff',
        fontSize: '1rem',
        fontWeight: 'bold',
    }
});

const Attendees = ({ attendees }) => {
    return (
        <>
            <SectionTitle variant="h4">Attendees</SectionTitle>
            <StyledPaper>
                <List>
                    {attendees.map((attendee, index) => (
                        <ListItem key={index} sx={{ borderBottom: '1px solid #404040' }}>
                            <StyledListItemText
                                primary={
                                    <a href={`https://admin.marsreel.com/${attendee.type}/${attendee.firebaseUid}`} style={{ color: '#12c099', textDecoration: 'none' }}>
                                        {attendee.name}
                                    </a>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </StyledPaper>
        </>
    );
};

export default Attendees;
