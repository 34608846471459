// pages/Lead.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const OverviewContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
});

const SectionTitle = styled(Typography)({
    color: '#12c099',
    fontWeight: 'bold',
    marginBottom: '1rem',
});

const Lead = () => {
    const { id } = useParams(); // Get the lead's document ID from the URL
    const [lead, setLead] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLead = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads/${id}`);
                setLead(response.data);
            } catch (error) {
                console.error('Error fetching lead:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLead();
    }, [id]);

    if (loading) {
        return <Typography variant="h6" color="inherit">Loading...</Typography>;
    }

    if (!lead) {
        return <Typography variant="h6" color="inherit">Lead not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <Typography variant="h4" gutterBottom>Lead Overview</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={3}>
                    {/* Display Avatar if available */}
                    <Avatar src={lead.avatar || ''} sx={{ width: 120, height: 120 }} />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Typography variant="h5">{lead.name}</Typography>
                    <Typography variant="body1">Email: {lead.email}</Typography>
                    <Typography variant="body1">Phone: {lead.phone}</Typography>
                    <Typography variant="body1">Zip Code: {lead.zipcode}</Typography>
                    <Typography variant="body1">Date Joined: {new Date(lead.createdAt).toLocaleDateString()}</Typography>
                </Grid>
            </Grid>
        </OverviewContainer>
    );
};

export default Lead;
