// session-components/SessionInfo.js
import React from 'react';
import { Box, Typography, Grid, Card, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';

const SectionTitle = styled(Typography)({
    marginBottom: '1rem',
    color: '#12c099',
    fontWeight: 'bold',
});

const SessionInfo = ({ name, description, eventDate, venueImages }) => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <Typography variant="body1" gutterBottom>{description}</Typography>
            <Typography variant="body2" gutterBottom>Event Date: {new Date(eventDate).toLocaleDateString()}</Typography>

            <SectionTitle variant="h5">Venue Images</SectionTitle>
            <Grid container spacing={2}>
                {venueImages.map((image, index) => image && (
                    <Grid item xs={12} sm={4} key={index}>
                        <Card sx={{ backgroundColor: '#282828' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={image}
                                alt={`Venue ${index + 1}`}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SessionInfo;
