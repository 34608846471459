import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, MenuItem } from '@mui/material';
import axios from 'axios';

const paymentMethods = [
    { label: 'Bank Transfer (Plaid)', value: 'plaid' },
    { label: 'Credit Card (Stripe)', value: 'stripe' },
    { label: 'PayPal', value: 'paypal' },
];

const PaymentModal = ({ open, handleClose, details, selectedPerson }) => {
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0].value);
    const [confirming, setConfirming] = useState(false);

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handlePayment = async () => {
        setConfirming(true);

        try {
            if (paymentMethod === 'stripe') {
                // Stripe payment logic
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/stripe/create-payout`, {
                    amount: selectedPerson.owed,
                    stripeAccountId: selectedPerson.stripeAccountId,  // Get this from the user object
                    description: `Payout to ${selectedPerson.name}`,
                });
                alert('Payment successful with Stripe');
            } else if (paymentMethod === 'paypal') {
                // PayPal payment logic
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/paypal/create-payment`, {
                    amount: selectedPerson.owed,
                    description: `Payout to ${selectedPerson.name}`,
                });
                window.location.href = data.links[1].href; // Redirect to PayPal for approval
            } else if (paymentMethod === 'plaid') {
                // Plaid payment logic
                const { data: accessTokenResponse } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/plaid/get-access-token`, {
                    public_token: selectedPerson.plaidPublicToken,  // Assume the user has a public token stored
                });

                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/plaid/create-transfer`, {
                    amount: selectedPerson.owed,
                    plaidAccountId: selectedPerson.plaidAccountId, // Get this from the user object
                    accessToken: accessTokenResponse.access_token,
                });
                alert('Payment successful with Plaid');
            }
        } catch (error) {
            alert('Payment failed: ' + error.message);
        }

        setConfirming(false);
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="payment-modal-title"
            aria-describedby="payment-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography id="payment-modal-title" variant="h6" component="h2">
                    Payment Details
                </Typography>
                <Typography id="payment-modal-description" sx={{ mt: 2 }}>
                    {details}
                </Typography>
                <TextField
                    select
                    label="Payment Method"
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    {paymentMethods.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} sx={{ mr: 2 }} disabled={confirming}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handlePayment} disabled={confirming}>
                        {confirming ? 'Processing...' : 'Proceed to Pay'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PaymentModal;
