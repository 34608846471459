import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import SessionInfo from '../components/session-components/SessionInfo';
import Reservations from '../components/session-components/Reservations';
import Attendees from '../components/session-components/Attendees';
import ProfitLoss from '../components/session-components/ProfitLoss';
import PaymentManagement from '../components/session-components/PaymentManagement';

// Footer component
const Footer = () => (
    <Box sx={{ mt: 4, padding: '2rem', backgroundColor: '#282828', textAlign: 'center', color: '#fff' }}>
        <Typography variant="body2">
            &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
        </Typography>
    </Box>
);

const fetchUserDetails = async (firebaseUid) => {
    const requests = [
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/athlete/${firebaseUid}`).catch(() => null),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/coach/${firebaseUid}`).catch(() => null),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/site-manager/${firebaseUid}`).catch(() => null),
    ];

    const [athleteRes, coachRes, siteManagerRes] = await Promise.all(requests);

    if (athleteRes?.data) {
        return { type: 'athlete', name: athleteRes.data.name, firebaseUid };
    } else if (coachRes?.data) {
        return { type: 'coach', name: coachRes.data.name, firebaseUid };
    } else if (siteManagerRes?.data) {
        return { type: 'siteManager', name: siteManagerRes.data.name, firebaseUid };
    }

    return { type: 'unknown', name: 'Unknown User', firebaseUid };
};

const fetchPayees = async (sessionId) => {
    try {
        const payeesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/session/${sessionId}/payees`);
        return payeesResponse.data; // The payees should include coaches, siteManagers, and attendees
    } catch (error) {
        console.error('Error fetching payees:', error);
        return { coaches: [], siteManagers: [], attendees: [] }; // Return empty arrays on error
    }
};

const Session = () => {
    const { id } = useParams(); // Get the session ID from the URL
    const [session, setSession] = useState(null);
    const [payees, setPayees] = useState({ coaches: [], siteManagers: [], attendees: [] });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSessionDetails = async () => {
            try {
                const sessionResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/session/${id}`);
                const sessionData = sessionResponse.data;

                // Fetch payees (coaches, siteManagers, attendees)
                const fetchedPayees = await fetchPayees(id);

                // Fetch details for reservedBy and attendees
                const detailedReservations = await Promise.all(
                    sessionData.reservedBy.map(async (firebaseUid) => {
                        const userDetails = await fetchUserDetails(firebaseUid);
                        return userDetails;
                    })
                );

                const detailedAttendees = await Promise.all(
                    sessionData.attendees.map(async (firebaseUid) => {
                        const userDetails = await fetchUserDetails(firebaseUid);
                        return userDetails;
                    })
                );

                setSession({
                    ...sessionData,
                    reservedBy: detailedReservations,
                    attendees: detailedAttendees,
                });

                setPayees(fetchedPayees);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching session details:', error);
                setLoading(false);
            }
        };

        fetchSessionDetails();
    }, [id]);

    if (loading) {
        return <Typography variant="h6" color="primary">Loading...</Typography>;
    }

    if (!session) {
        return <Typography variant="h6" color="error">Session not found.</Typography>;
    }

    return (
        <Box sx={{ padding: '2rem', backgroundColor: '#1c1c1c', color: '#fff', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <SessionInfo
                    name={session.name}
                    description={session.description}
                    eventDate={session.eventDate}
                    venueImages={[session.venueImage1, session.venueImage2, session.venueImage3]}
                />

                {/* Pass attendees to PaymentManagement */}
                <PaymentManagement
                    coaches={(session.coachNames ?? []).map((name, i) => ({
                        name,
                        rate: session.coachRate ?? 0, // Use default value if coachRate is undefined
                        owed: (session.coachRate ?? 0) * (session.coachRatings?.[i] || 2), // Use default value if coachRatings is undefined
                        firebaseUid: session.coachUids?.[i] ?? '' // Use an empty string if firebaseUid is undefined
                    }))}

                    siteManagers={(session.siteManagerNames ?? []).map((name, i) => ({
                        name,
                        rate: session.siteManagerRate ?? 0, // Use default value if siteManagerRate is undefined
                        owed: (session.siteManagerRate ?? 0) * (session.siteManagerAvatars?.[i] || 2), // Use default value if siteManagerAvatars is undefined
                        firebaseUid: session.siteManagerUids?.[i] ?? '' // Use an empty string if firebaseUid is undefined
                    }))}

                    attendees={(session.attendees ?? []).map(attendee => attendee.firebaseUid ?? '')} // Ensure attendees is an array
                    sessionId={id} // <--- Pass sessionId here from useParams

                />

                <Reservations reservations={session.reservedBy} />
                <Attendees attendees={session.attendees} />
                <ProfitLoss profitLossData={session.profitLossData} />
            </Box>

            <Footer />
        </Box>
    );
};

export default Session;
