import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#282828',
    color: '#fff',
    marginBottom: '2rem',
});

const SectionTitle = styled(Typography)({
    marginBottom: '1rem',
    color: '#12c099',
    fontWeight: 'bold',
    fontSize: '1.5rem',
});

const StyledTableCell = styled(TableCell)({
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 'bold',
});

const ProfitLoss = ({ profitLossData }) => {
    return (
        <>
            <SectionTitle variant="h4">Profit/Loss</SectionTitle>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell>Amount ($)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {profitLossData?.map((entry, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>{entry.category}</StyledTableCell>
                                <StyledTableCell>{entry.amount.toLocaleString()}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default ProfitLoss;
