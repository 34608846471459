import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Grid, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const OverviewContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const ContentContainer = styled(Box)({
    flexGrow: 1,
    paddingBottom: '3rem',
});

const SectionTitle = styled(Typography)({
    color: '#12c099',
    fontWeight: 'bold',
    marginBottom: '1rem',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
});

const Employee = () => {
    const { firebaseUid } = useParams(); // Use firebaseUid as the URL parameter
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false); // State for editing
    const [formValues, setFormValues] = useState({}); // Store the form values

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/employee/${firebaseUid}`);
                setEmployee(response.data);
                setFormValues(response.data); // Initialize form values
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
                setLoading(false);
            }
        };
        fetchEmployee();
    }, [firebaseUid]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/update-employee/${firebaseUid}`, formValues);
            setEditing(false); // Disable editing mode after saving
        } catch (error) {
            console.error('Error updating employee data:', error);
        }
    };

    if (loading) {
        return <CircularProgress color="inherit" />;
    }

    if (!employee) {
        return <Typography variant="h6" color="inherit">Employee not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <ContentContainer>
                <Typography variant="h4" gutterBottom>Employee Overview</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <Avatar src={employee.avatarImageUrl || ''} sx={{ width: 120, height: 120 }} />
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Typography variant="h5">{employee.name}</Typography>
                        <Typography variant="body1">Title: {employee.title}</Typography>
                        <Typography variant="body1">
                            Location: {employee.location.city.join(', ')}, {employee.location.state.join(', ')}
                        </Typography>
                        <Typography variant="body1">Email: {employee.email}</Typography>
                        <Typography variant="body1">Phone: {employee.phoneNumber}</Typography>
                        <Typography variant="body1">Sport: {employee.sport}</Typography>
                    </Grid>
                </Grid>
                <Paper sx={{ padding: '1.5rem', backgroundColor: '#202020', color: '#fff', borderRadius: '8px', marginTop: '20px' }}>
                    <SectionTitle variant="h6">Edit Employee Information</SectionTitle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Name"
                                name="name"
                                value={formValues.name}
                                onChange={handleInputChange}
                                fullWidth
                                disabled={!editing}
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#12c099' } }}
                                variant="outlined"
                                sx={{ backgroundColor: '#333' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                name="email"
                                value={formValues.email}
                                onChange={handleInputChange}
                                fullWidth
                                disabled={!editing}
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#12c099' } }}
                                variant="outlined"
                                sx={{ backgroundColor: '#333' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone Number"
                                name="phoneNumber"
                                value={formValues.phoneNumber}
                                onChange={handleInputChange}
                                fullWidth
                                disabled={!editing}
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#12c099' } }}
                                variant="outlined"
                                sx={{ backgroundColor: '#333' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Title"
                                name="title"
                                value={formValues.title}
                                onChange={handleInputChange}
                                fullWidth
                                disabled={!editing}
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#12c099' } }}
                                variant="outlined"
                                sx={{ backgroundColor: '#333' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Sport"
                                name="sport"
                                value={formValues.sport}
                                onChange={handleInputChange}
                                fullWidth
                                disabled={!editing}
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#12c099' } }}
                                variant="outlined"
                                sx={{ backgroundColor: '#333' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {editing ? (
                                <Button variant="contained" color="primary" onClick={handleSave}>
                                    Save Changes
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary" onClick={() => setEditing(true)}>
                                    Edit Employee
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </ContentContainer>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </OverviewContainer>
    );
};

export default Employee;
