import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';  // Import the firebase auth
import { useNavigate } from 'react-router-dom';

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
});

const VideoBackground = styled('video')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Dark tint
    zIndex: 0,
});

const Logo = styled('img')({
    width: '200px',
    zIndex: 1,
    marginBottom: '20px',
});

const LoginBox = styled('div')({
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const StyledTextField = styled(TextField)({
    marginBottom: '15px',
    width: '300px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
    '& .MuiInputLabel-root': {
        color: '#ccc',
    },
    '& .MuiInputBase-input': {
        color: '#fff',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
});

const LoginButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    width: '300px',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
});

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            // Sign in the user
            await signInWithEmailAndPassword(auth, email, password);

            // Get the current user and token with custom claims
            const user = auth.currentUser;
            const idTokenResult = await user.getIdTokenResult();

            // Check if the user has the 'employee' role
            if (idTokenResult.claims.role === 'employee') {
                console.log('Login successful: Employee role verified');
                navigate('/dashboard');  // Navigate to the dashboard
            } else {
                console.log('Access denied: User is not an employee');
                alert('Access denied: You do not have permission to access this app.');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('Login failed: ' + error.message);
        }
    };


    return (
        <Root>
            <Overlay />
            <LoginBox>
                <Logo src="https://d3dbfxrvl5y81z.cloudfront.net/academylogo.jpg" alt="MarsReel" />
                <StyledTextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <StyledTextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <LoginButton variant="contained" onClick={handleLogin}>
                    Login
                </LoginButton>
            </LoginBox>
        </Root>
    );
};

export default Login;
