import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Typography,
    IconButton,
    Button,
    Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Styled components for the input and table
const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '1rem',
    },
});

const StyledTableContainer = styled(TableContainer)(({ expanded }) => ({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
    transition: 'margin-top 0.3s ease',
    marginTop: expanded ? '450px' : '20px',
}));

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    color: '#fff',
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
});

const EmployeesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
});

const AddEmployeeForm = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: '#282828',
    borderRadius: '8px',
    color: '#fff',
});

const Employees = () => {
    const [employees, setEmployees] = useState([]);
    const [sports, setSports] = useState([]); // To store sports fetched from the backend
    const [newEmployee, setNewEmployee] = useState({
        name: '',
        title: '',
        city: '',
        state: '',
        country: '',
        sport: '',  // Adding sport field to new employee
        email: '',
        phoneNumber: '',
    });
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const searchBoxRef = useRef(null);

    // Fetch Employees from Backend
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/employees`);
                setEmployees(response.data);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        const fetchSports = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sports`);
                setSports(response.data);  // Fetch sports from the backend
            } catch (error) {
                console.error('Error fetching sports:', error);
            }
        };

        fetchEmployees();
        fetchSports();
    }, []);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEmployee((prev) => ({ ...prev, [name]: value }));
    };

    // Handle Google Places Autocomplete
    const handlePlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places.length > 0) {
            const place = places[0];
            const addressComponents = place.address_components;

            const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name;
            const state = addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.short_name;
            const country = addressComponents.find((comp) => comp.types.includes('country'))?.long_name;

            setNewEmployee((prev) => ({
                ...prev,
                city: city || '',
                state: state || '',
                country: country || '',
            }));
        }
    };

    const handleAddEmployee = async () => {
        if (!newEmployee.name || !newEmployee.title) {
            alert('Please fill in both the name and title fields.');
            return;
        }

        // Prepare employee data with both individual fields and location object
        const employeeData = {
            ...newEmployee,
            city: newEmployee.city,       // Individual city field
            state: newEmployee.state,     // Individual state field
            country: newEmployee.country, // Individual country field
            sport: newEmployee.sport,     // Include selected sport
            location: {
                city: [newEmployee.city],   // Array for location purposes
                state: [newEmployee.state], // Array for location purposes
                country: newEmployee.country
            },
            address: {
                street: newEmployee.street || '',   // Add street address if available
                city: newEmployee.city,             // Pass as string
                state: newEmployee.state,           // Pass as string
                zipCode: newEmployee.zipCode || '', // Add zipCode if available
                country: newEmployee.country        // Pass as string
            }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-employee`, employeeData);
            setEmployees([...employees, response.data]);
            setNewEmployee({
                name: '',
                title: '',
                city: '',
                state: '',
                country: '',
                sport: '', // Clear sport field after adding employee
                email: '',
                phoneNumber: '',
                street: '',
                zipCode: ''
            });
            setExpanded(false);
        } catch (error) {
            if (error.response && error.response.data === "The email address is already in use by another account.") {
                alert('The email address is already in use. Please use a different email.');
            } else {
                console.error('Error adding employee:', error);
            }
        }
    };


    const handleDeleteEmployee = async (firebaseUid) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this employee?');
        if (!confirmDelete) return;

        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete-employee/${firebaseUid}`);
            setEmployees(employees.filter((employee) => employee.firebaseUid !== firebaseUid));
        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };

    return (
        <EmployeesContainer>
            <HeaderContainer>
                <Title>Employees</Title>
                <IconButton onClick={() => setExpanded(!expanded)} sx={{ color: '#12c099' }}>
                    <AddCircleIcon />
                    <Typography variant="body1" sx={{ marginLeft: '8px', color: '#12c099' }}>
                        Add New Employee
                    </Typography>
                    {expanded ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </HeaderContainer>

            <SearchBar
                variant="outlined"
                label="Search Employees"
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Collapse in={expanded}>
                <AddEmployeeForm>
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                        <StandaloneSearchBox onLoad={ref => (searchBoxRef.current = ref)} onPlacesChanged={handlePlacesChanged}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter city, state, or country"
                                sx={{ backgroundColor: '#202020', color: '#fff', width: '100%' }}
                                inputProps={{ style: { color: '#fff' } }}
                            />
                        </StandaloneSearchBox>
                    </LoadScript>

                    <TextField
                        label="Name"
                        name="name"
                        value={newEmployee.name}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{
                            backgroundColor: '#202020',
                            width: '100%',
                            input: { color: '#fff' }, // Input text color
                        }}
                        InputLabelProps={{ style: { color: '#12c099' } }} // Label color
                    />

                    <TextField
                        label="Email"
                        name="email"
                        value={newEmployee.email}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{
                            backgroundColor: '#202020',
                            width: '100%',
                            input: { color: '#fff' }, // Input text color
                        }}
                        InputLabelProps={{ style: { color: '#12c099' } }} // Label color
                    />

                    <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        value={newEmployee.phoneNumber}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{
                            backgroundColor: '#202020',
                            width: '100%',
                            input: { color: '#fff' }, // Input text color
                        }}
                        InputLabelProps={{ style: { color: '#12c099' } }} // Label color
                    />

                    <TextField
                        label="Title"
                        name="title"
                        value={newEmployee.title}
                        onChange={handleInputChange}
                        variant="outlined"
                        select
                        SelectProps={{
                            native: true,
                        }}
                        sx={{
                            backgroundColor: '#202020',
                            width: '100%',
                            color: '#fff', // Ensure this applies to the text color
                            '& .MuiSelect-root': {
                                color: '#fff', // Ensure selected option text color is white
                            },
                            '& .MuiSvgIcon-root': {
                                color: '#fff', // Ensure the dropdown icon is white
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#12c099', // Ensure the border color remains consistent
                            },
                        }}
                        InputLabelProps={{ style: { color: '#12c099' } }} // Label color
                    >
                        <option value="" disabled>Select a Title</option>
                        <option value="City Manager I">City Manager I</option>
                        <option value="City Manager II">City Manager II</option>
                        <option value="City Manager III">City Manager III</option>
                        <option value="State Manager I">State Manager I</option>
                        <option value="State Manager II">State Manager II</option>
                        <option value="State Manager III">State Manager III</option>
                        <option value="Country Manager I">Country Manager I</option>
                        <option value="Country Manager II">Country Manager II</option>
                        <option value="Country Manager III">Country Manager III</option>
                    </TextField>

                    <TextField
                        label="Sport"
                        name="sport"
                        value={newEmployee.sport}
                        onChange={handleInputChange}
                        variant="outlined"
                        select
                        SelectProps={{
                            native: true,
                        }}
                        sx={{
                            backgroundColor: '#202020',
                            width: '100%',
                            color: '#fff', // Ensure this applies to the text color
                            '& .MuiSelect-root': {
                                color: '#fff', // Ensure selected option text color is white
                            },
                            '& .MuiSvgIcon-root': {
                                color: '#fff', // Ensure the dropdown icon is white
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#12c099', // Ensure the border color remains consistent
                            },
                        }}
                        InputLabelProps={{ style: { color: '#12c099' } }} // Label color
                    >
                        <option value="" disabled>Select a Sport</option>
                        {sports.map((sport) => (
                            <option key={sport._id} value={sport.name}>
                                {sport.name}
                            </option>
                        ))}
                    </TextField>


                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#12c099',
                            color: '#000',
                            marginTop: '10px',
                        }}
                        onClick={handleAddEmployee}
                    >
                        Add Employee
                    </Button>
                </AddEmployeeForm>
            </Collapse>

            {/* Display Employee Table */}
            <StyledTableContainer component={Paper} expanded={expanded}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>Name</CompactTableCell>
                            <CompactTableCell>Title</CompactTableCell>
                            <CompactTableCell>City</CompactTableCell>
                            <CompactTableCell>State</CompactTableCell>
                            <CompactTableCell>Country</CompactTableCell>
                            <CompactTableCell>Sport</CompactTableCell>
                            <CompactTableCell>Email</CompactTableCell>
                            <CompactTableCell>Actions</CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((employee) => (
                            <TableRow key={employee.firebaseUid}>
                                <CompactTableCell>
                                    <Link to={`/employee/${employee.firebaseUid}`} style={{ color: '#12c099', textDecoration: 'none' }}>
                                        {employee.name}
                                    </Link>
                                </CompactTableCell>
                                <CompactTableCell>{employee.title}</CompactTableCell>
                                <CompactTableCell>
                                    {employee.address?.city || employee.location?.city?.[0] || ''}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {employee.address?.state || employee.location?.state?.[0] || ''}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {employee.address?.country || employee.location?.country || ''}
                                </CompactTableCell>
                                <CompactTableCell>{employee.sport || ''}</CompactTableCell>
                                <CompactTableCell>{employee.email}</CompactTableCell>
                                <CompactTableCell>
                                    <IconButton onClick={() => handleDeleteEmployee(employee.firebaseUid)}>
                                        <DeleteIcon sx={{ color: '#ff1744' }} />
                                    </IconButton>
                                </CompactTableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </StyledTableContainer>

            <PaginationBox>
                <Pagination
                    count={Math.ceil(employees.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
        </EmployeesContainer>
    );
};

export default Employees;
