import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Athletes from './pages/Athletes';
import Coaches from './pages/Coaches';
import SiteManagers from './pages/SiteManagers';
import Session from './pages/Session';
import Financials from './pages/Financials';
import Locations from './pages/Locations';
import Sessions from './pages/Sessions';
import ContentFeed from './pages/ContentFeed';
import Employees from './pages/Employees';
import Athlete from './pages/Athlete';
import Coach from './pages/Coach';
import SiteManager from './pages/SiteManager';
import Employee from './pages/Employee';
import BookingDashboard from './pages/BookingDashboard';
import Waitlist from './pages/Waitlist';
import WaitlistMember from './pages/Waitlist-member';
import Markets from './pages/Markets';
import CountryOverview from './pages/CountryOverview';
import StateOverview from './pages/StateOverview';
import CityOverview from './pages/CityOverview';
import Leads from './pages/Leads'; // Import the Leads page
import Lead from './pages/Lead'; // Import the individual Lead page
import Login from './pages/Login';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const App = () => {
    const [open, setOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                navigate('/login'); // Redirect to login if not authenticated
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        getAuth().signOut();  // Log the user out
        navigate('/login'); // Redirect to login page
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            {location.pathname !== '/login' && isAuthenticated && (
                <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} handleLogout={handleLogout} />
            )}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 0,
                    transition: 'margin 0.3s',
                    marginLeft: open ? '0px' : '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    overflowY: 'auto',
                }}
            >
                <Routes>
                    <Route path="/login" element={<Login />} />
                    {isAuthenticated && (
                        <>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/athletes" element={<Athletes />} />
                            <Route path="/athlete/:firebaseUid" element={<Athlete />} />
                            <Route path="/coaches" element={<Coaches />} />
                            <Route path="/coach/:firebaseUid" element={<Coach />} />
                            <Route path="/sitemanagers" element={<SiteManagers />} />
                            <Route path="/site-manager/:firebaseUid" element={<SiteManager />} />
                            <Route path="/session/:id" element={<Session />} />
                            <Route path="/financials" element={<Financials />} />
                            <Route path="/countryoverview/:country" element={<CountryOverview />} />
                            <Route path="/stateoverview/:state" element={<StateOverview />} />
                            <Route path="/cityoverview/:city" element={<CityOverview />} />
                            <Route path="/sessions" element={<Sessions />} />
                            <Route path="/contentfeed" element={<ContentFeed />} />
                            <Route path="/employees" element={<Employees />} />
                            <Route path="/employee/:firebaseUid" element={<Employee />} />
                            <Route path="/bookingdashboard" element={<BookingDashboard />} />
                            <Route path="/leads" element={<Leads />} /> {/* Leads page route */}
                            <Route path="/leads/:id" element={<Lead />} /> {/* Individual Lead page route */}
                            <Route path="/waitlist" element={<Waitlist />} />
                            <Route path="/waitlist-member/:id" element={<WaitlistMember />} />
                            <Route path="/markets" element={<Markets />} />
                            <Route path="/" element={<Dashboard />} />
                        </>
                    )}
                </Routes>
            </Box>
        </Box>
    );
}

export default App;
